.header{
    width: 100%;
    background: linear-gradient(270deg, rgba(10, 218, 208, 0.59) -10.91%, #1C3472 105.52%);
    position: absolute;
    z-index: 4;
    left: 0;
    right: 0;
    overflow-x: hidden;
    padding: 7px 0;
    
}

.header .logo a {
    display: flex;
}

.header .logo img{
    overflow: hidden;
    width: auto;
    margin: 0 8vw;
}
.header .links{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header .links a{
    color: #fff;
    margin: 0 1.5vw;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
}
.header .links a.active{
    color: #000;
}

.header .hmenu {
    display: none;
}

@media only screen and (max-width: 1080px) {
    .header .links{
        display: none;
    }
    .header .hmenu {
        display: block;
    }
}