.test .links {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(0deg, rgba(10, 218, 208, 0.59) -10.91%, #1C3472 105.52%);
}

.test .links .hmenu-link{
  margin: 1em;
  color: white;
}