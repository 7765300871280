.MNTable table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
}

.MNTable .MNTable-header {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  gap: 1em;
  flex-wrap: wrap;
}

.MNTable .table-container {
  overflow: auto;
}

.MNTable .lower-table-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1em;
  margin: 1em;
}

.banner .container h1 {
  margin: 1em 0;
}

.MNTable table thead {
  background: #f5f5f5;
}
.MNTable table thead th {
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid rgb(211, 211, 211);
  cursor: pointer;
  text-align: center;
}
.MNTable table tbody td {
  height: 40px;
  border-bottom: 1px solid #ddd;
}

.MNTable table tbody tr:hover td {
  background: #dddddd7d;
}

.MNTable button {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  text-transform: uppercase;
  margin: 0 5px;
}
.MNTable button:hover {
  background: #f5f5f5;
  border-color: #999;
}