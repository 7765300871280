.newsletter {
  position: relative;
}

.newsletter .pageTitle {
  margin: 1em 0.5em;
}

.newsletter .posts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.newsletter .bottomSection {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
}
.newsletter .bottomSection .btn {
  background-color: var(--primary-color1);
  margin: 0 !important;
}

.newsletter .datePicker {
  margin: 1em;
}
@media only screen and (max-width: 526px) {
  .newsletter .top-section {
    flex-direction: column;
  }
}


.newsletter .container{
  padding: 30vh 5vw 10vh 5vw;
  display: 'flex';
  flex-direction: 'column';
  justify-content: 'center';
  height: 80vh;
}
.newsletter h1{
  color: #fff;
}.newsletter .banner p{
  color: #fff;
}