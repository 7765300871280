.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  height: 36vh;
  width: 50vw;
  top: 36vh;
  left: 25vw;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  z-index: 100;
}

.loading .text {
  text-align: center;
  margin-top: 1em;
}

.dna-loader-3d {
  --grey-color: #454344;
  --blue-color: #00fff3;
  --red-color: #1c3472;
  --ball-width: 0.375rem;
  --rotate-radius: 1.5rem;
  --rotate-duration: 4s;
  --anime: var(--rotate-duration) linear both infinite;
  --count: 30;
  transform: rotate(0deg);
  display: flex;
  flex-direction: column;
  perspective: 500px;
}
.dna-loader-3d .orbit {
  transform-style: preserve-3d;
  --rotate-delay-even: calc(
    var(--rotate-duration) / var(--count) * var(--i) * -2
  );
  --rotate-delay-odd: calc(
    var(--rotate-delay-even) - calc(var(--rotate-duration) * 0.5)
  );
  position: relative;
  width: var(--ball-width);
  height: var(--ball-width);
  animation: loading-rotate-y var(--anime) var(--rotate-delay);
}
.dna-loader-3d .orbit .ball {
  transform-style: preserve-3d;
  position: relative;
  width: 100%;
  height: 100%;
  transform: translateZ(calc(var(--rotate-radius) * -1));
}
.dna-loader-3d .orbit .ball::before {
  transform-style: preserve-3d;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: var(--ball-color);
  border-radius: 50%;
  animation: loading-rotate-y-reverse var(--anime) var(--rotate-delay);
}
.dna-loader-3d .orbit:nth-child(odd) {
  --ball-color: var(--blue-color);
  --rotate-delay: var(--rotate-delay-odd);
}
.dna-loader-3d .orbit:nth-child(even) {
  --ball-color: var(--red-color);
  --rotate-delay: var(--rotate-delay-even);
}

@keyframes loading-rotate-y {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1turn);
  }
}
@keyframes loading-rotate-y-reverse {
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(-1turn);
  }
}
