.chat .chatError {
    position: fixed;
    bottom: 5vh;
    z-index: 100;
    margin: 8px;
    max-width: 600px;
}

@media only screen and (max-width: 650px) {
    .chat .chatError {
        left: 16px !important;
        right: 16px !important; 
    }
}