.planCard {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  max-width: 500px;
  min-height: 300px;
  width: 400px;
  height: 500px;
  background: var(--bg-light);
  overflow: hidden;
  margin: 1em;
}
.planCard img {
  position: absolute;
  width: 100%;
  top: 0;
}
.planCard .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  justify-content: center;
}
.planCard .title {
  color: white;
  margin: 5px 20px 5px 25px;
  height: 2em;
}
.planCard p {
  /* text-align: center; */
  margin: 0em 1em;
}
.planCard .phrase {
  background: #ffffff;
  border: none;

  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 60px;
  margin: 15px 20% 5px 25px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
}
.planCard .details {
  background: #ffffff;
  border: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 30%;
  margin: 20px 25px 5px 25px;
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}

.bottom {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1.5em;
}
.btn {
  margin: 1.5em !important;
}

/*media*/

@media (max-width: 450px){

  .planCard {

    width: 300px;
  }
}

@media (max-width: 310px){

  .planCard {

    width: 250px;
    height: 600px;
  }
}
