.post {
  margin-top: 1.5em;
  border-radius: 10px;
  background-color: var(--bg-light);
  width: 85vw;
  max-width: 85vw;
  min-width: 85vw;
}

.post .lower {
  background-color: white;
  display: flex;
  gap: 1em;
  padding: 1em;
  margin: 1em;
  max-width: 85vw;
  border-radius: 10px;
}

.post .upper .text {
  line-height: 1.5em;
}

.post .lower, .post .upper {
  /* width: 100%; */
}

.post .lower {
  margin-bottom: 1em;
}

.post .lower .title {
  font-weight: 500;
  font-size: larger;
}

.post .lower .text {
  /* width: 30%; */
}

.post .lower .media {
  width: 30vw;
}

.post .lower .media img{
  width: 30vw;
}

.post .upper {
  padding: 0.5em;
  display: flex;
  gap: 1em;
  align-items: center;
  border-bottom: 3px solid white;
}

.post .swiper-wrapper {
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .post {
    width: 100vw;
  }

  .post .lower {
    display: flex;
    flex-direction: column;
  }
  .post .lower .media {
    width: 100%;
  }

  .post .lower .media img{
    width: 100%;
  }
  
}
