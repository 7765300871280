.MNTableFilters .dialog {
    width: 70vw !important;
}

.MNTableFilters-Overlay {
    width: 70vw;
    max-width: 400px;
}

input {
    width: 100% !important;
}