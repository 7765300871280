.homePageButtomSlider .swiper-img-container {
  border: 1px solid gold;
  cursor: pointer;
}

.important-partner-ad {
  width: 100%;
}
.important-partner-logo {
  transform: scale(1.3);
  max-width: 120%;
}