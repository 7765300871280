.advantages {
  width: 100vw;
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
}
.advantages .content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 3em;
  overflow-x: auto;
  overflow-y: hidden;
  max-height: 540px;
}

.advantage {
  display: flex;
  flex-direction: column;
  width: 100px;
  height: 150px;
}

.advantage p {
  color: var(--primary-color1);
  font-weight: 500;
  text-align: center;
  margin-top: 10px;
}

.bottom-sec {
  flex-direction: column;
}

@media (max-width: 900px) {
  .advantages .content {
    height: 55vh;
    flex-direction: column;
  }
  .advantages h1 {
    font-size: 1.5rem;
    margin-bottom: 50px;
  }
}
