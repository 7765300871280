section.homeSection {
  max-height: 800px;
  padding-top: 150px;
  width: 100vw;
  background-color: #f6f6f6;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homeSection .mainContainer {
  max-width: 1700px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.hero-img {
  width: 500px;
  z-index: 2;
}
section.homeSection .content {
  padding: 1em;
  display: flex;
  width: calc(100vw - 500px - 2em);
  z-index: 3;
}

.homeSection .slideContainer {
  display: flex;
  flex-direction: column;
}

.homeSection .content h1 {
  margin: 1em 0em;
}

/*animations*/
@keyframes scalling {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes scalling2 {
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}

@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

/*media*/

@media (max-width: 900px) {
  .hero-img {
    width: 350px;
  }
  section.homeSection .content {
    width: calc(100vw - 350px - 2em);
  }
}

@media (max-width: 600px) {
  .homeSection .mainContainer {
    max-width: 1700px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  section.homeSection .content {
    padding: 0;
    display: block;
    position: absolute;
    width: calc(100vw - 2 * 3em);
    top: 15%;
    text-align: center;
    margin: 0 3em;
  }
  .hero-img {
    margin-right: 300px;
    opacity: 0;
  }
}
