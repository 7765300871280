.contactUs {
  min-height: calc(100vh - var(--header-height));
  --width: 75vw;
}

.contactUs .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 3vh;
}

.contactUs .form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1em;
  width: var(--width);
  background-color: var(--primary-color1);
  padding: 1em 1em 0 1em;
  margin-top: calc(var(--header-height) + 1em);
  margin-bottom: 1em;
  margin-top: calc(-70vh + var(--header-height) + 5em);
  
}

.contactUs .contactForm {
  padding: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: var(--width);
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px rgba(213, 213, 213, 1);
}

.contactUs .input {
  width: 90%;
  margin-bottom: 1em;
}

.contactUs textarea {
  margin-bottom: 20vh;
}

.contactUs .submit-btn {
  background-color: var(--primary-color1);
}

.contactUs .form-container p {
  width: 100%;
}

.contactUs .banner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactUs .form-container h1 {
  color: #fff;
}
.contactUs .form-container p {
  color: #fff;
}

.contactUs .content .other-means {
  background-color: var(--bg-light);
  width: var(--width);
  padding: 2em;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.contactUs .content .other-means .container {
  background-color: var(--bg-light);
}
