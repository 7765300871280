section.ourPlans {
    width: 100vw;
}
section.ourPlans h1{
    font-size: 2rem;
    margin-bottom: 1em;

}
section.ourPlans .plans{
    display: grid;
    grid-template-columns: repeat(2,1fr) ;
    place-items: center;  
    gap: 50px;
}
.mid-section{
    flex-direction: column;
}


/*media*/
@media (max-width: 900px){
    section.ourPlans .plans{
      grid-template-columns: repeat(1,1fr);
    }
}
  