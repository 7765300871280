footer{
    width: 100vw;
    background: #1C3472; 
    margin-top: 1.5em;
}
footer .backToTop {
    width: 100vw;
    padding: 10px 0;
    cursor: pointer;
    text-align: center;
    background: #233259;
    color: white;
}
footer p, footer a {
    color: #fff;
}
footer .footerContent{
    width: 100vw;
    display: flex;
    align-items: center;
    align-content: center;
    background: #1C3472;
    flex-direction: row;
    padding: 15px 0;
}
footer .footerLogo, footer .footerLinks, footer .footerSocial{
    margin-top: 1em;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
}
footer img.logo{
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
}
footer ul li{
    display: inline-block;
    margin: 0 10px;
}
footer .rights{
    color: #ffffff55;
    font-size: 16px;
    text-align: center;
    padding: 1em;
}

@media only screen and (max-width: 700px) {
    footer .footerContent {
        flex-direction: column;
    }
}