.hero-svg{
    position:absolute;
    left:50%;
    top:50%;
    filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.25));
}

.squares{
    position: absolute;
    z-index: 1;
    left: -3%;
    top: 15%;
    filter: drop-shadow(3px 4px 3px rgba(0, 0, 0, 0.25));
    animation: scalling2 5s ease alternate infinite;
}


.square{
    width: 415px;
    
}
.circles{
    position: absolute;
    z-index: 1;
    width: 50px;
    height: 60px;
    left: 45%;
    top: 45%;
}
.right-circles{
    left: 90%;
    top: 70%;
    transform: rotate(190deg);
    
}
.cross{
    position: absolute;
    left:25%;
    top:30%; 
    width: 50px;
    height: 60px;
    z-index: 1;
}
.right-cross{
    left: 90%;
    top: 5%;
    mix-blend-mode: normal;
transform: matrix(-1, 0, 0, 1, 0, 0);
}
.circle1{
    width: 35px;
    left:100%;
    top:0%;
    animation: scalling 5s ease alternate infinite;
}
.circle2{
    width: 25px;
    left:0%;
    top:45%;
    animation: scalling 5s ease alternate infinite;
}
.circle3{
    width: 20px;
    left:0%;
    top:100%;
    animation: scalling 5s ease alternate infinite;
}
.x1{
    left:100%;
    top:100%;
     
    width: 28px;
    animation: rotating 4s ease-in-out alternate infinite;
}
.x2{
    left:0%;
    top:0%;
    width: 35px;
    animation: rotating 4s ease-in-out alternate infinite;
}



@media (max-width: 900px){
    .squares{
        z-index: 1;
        left: -10%;
        top: -5%;

    }
    .square{
        width: 300px;
        
    }
    .cross{
        display: none;
    }
  }