.aboutUS .about-contant {
  flex-direction: column;
  justify-content: center;
  margin-top: 3em;
  margin-bottom: 3em;
}
.about-contant h1 {
  width: fit-content;
}
.aboutUS {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  justify-items: left;
  gap: min(4vw, 50px);
  padding: 0 50px;
}

.aboutUS .our-partners {
  direction: ltr !important;
}
.svg1 {
  width: auto;
  max-width: 100%;
  height: min(30vh, 15vw);
  grid-column: span 3 / auto;
  padding-left: 50px;
}

.svg2 {
  width: min(30vh, 15vw);
  height: auto;
  max-height: 60vh;
  max-width: 100%;
  grid-column: span 1 / auto;
}

.about-text {
  grid-column: span 2 / auto;
}

.aboutUS h2 {
  grid-column: span 2 / auto;
  font-size: max(min(4vw, 60px), 1rem);
  margin-bottom: 3vw;
}

.aboutUS p {
  grid-column: span 2 / auto;
  font-size: max(min(2vw, 25px), 1rem);
  margin-bottom: 4vw;
}

.banner h2 {
  position: absolute;
  font-size: 2rem;
  color: white;
  bottom: 45%;
  margin: 0 50px;
}
.banner button {
  position: absolute;
  font-size: 1.2rem;
  color: white;
  bottom: 35%;
  background-color: var(--primary-color2);
  padding: 20px;
  border-radius: 50px;
  border: none;
}
.banner button:hover {
  cursor: pointer;
}

.temp {
  margin: 300px auto;
}

.temp-img {
  width: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.temp-contant {
  width: 45%;
}

.temp-contant p {
  color: black;
  font-size: max(min(2vw, 25px), 1rem);
  line-height: 2.5rem;
}
.temp-contant h2 {
  color: var(--primary-color2);
  font-size: max(min(4vw, 60px), 1rem);
  margin-bottom: 20px;
}

.stat-bg {
  width: 65vw;
  position: relative;
  padding: 50px 0;
  margin: 5em auto;
  background-color: var(--primary-color1);
  transform: rotate(4.5deg);
}

.stat-bg::before {
  content: " ";
  display: block;
  background-color: red;
  position: absolute;
  width: 125px;
  height: 50px;
  top: -49px;
  left: 0;
  background-color: #182c5f;
}
.stat-bg::after {
  content: " ";
  display: block;
  background-color: red;
  position: absolute;
  width: 125px;
  height: 50px;
  bottom: -49px;
  right: 0;
  background-color: #182c5f;
}
.stat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  color: white;
}

.stat-container {
  width: 100%;
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  transform: rotate(-4.5deg);
  gap: 3em;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat h2 {
  font-size: 1.7rem;
  margin: 10px 0;
  text-align: center;
}

/*media*/

@media (max-width: 1000px) {
  .stat h2 {
    font-size: 1.5rem;
  }
  .temp {
    margin: 100px auto;
  }
  .temp-img {
    display: none;
  }
  .temp-contant {
    width: 80%;
    margin: auto;
  }
  .temp-contant {
    text-align: center;
  }
}
@media (max-width: 750px) {
  .banner h2 {
    font-size: 1.5rem;
    text-align: center;
  }
  .stat-container {
    flex-direction: column;
  }
  .svg2 {
    grid-column: span 3 / auto;
    transform: rotate(90deg);
    margin-left: 40%;
  }
  .svg1 {
    padding-left: 0;
  }
  .about-text {
    grid-column: span 3 / auto;
    text-align: center;
  }
  .stat-bg::after,
  .stat-bg::before {
    display: none;
  }
  .stat {
    margin: 1em;
  }
  .stat-container,
  .stat-bg {
    transform: rotate(0);
    border-radius: 10px;
  }
}

@media only screen and (max-width: 900px) {
  .swiper-img-container {
    width: 70vw;
  }
}
